@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
*{
  font-family: 'Roboto', sans-serif;
}
textarea:focus, input:focus{
  outline: none;
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  color: white;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}