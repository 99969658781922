.modal {
    display: flex;
    z-index: 9;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  }

  .modalBody{
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
  }
  
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 400px;
    max-width: 90%;
  }
  
  .flex {
    display: flex;
    color: black;
  }

  .inputNumber{
    padding: 0.4rem 0.4rem;
    font-size: 1rem;
    border-radius: 5px;
    margin-top: 1rem;
  }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

  .loginBtn{
    border: none;
    font-size: 1rem;
    padding: 0.5rem 0.2rem;
    border-radius: 5px;
    font-weight: 500;
    background-color: crimson;
    color: white;
    margin: 1rem 0rem 2rem 0rem;
  }

  .openModalBtn{
    border: none;
    background-color: crimson;
    color: white;
    padding: 0.4rem 0.6rem;
    font-size: 1.5rem;
    font-weight: 600;
    border-radius: 5px;
    margin-top: 1rem;
  }

  @media screen and (max-width: 600px) {
    .openModalBtn{
        font-size: 1.2rem;
    }
  }